import React, { Component } from "react"
import { TextField } from "@material-ui/core";

const tableItemStyle = {fontWeight: 300, color: 'rgb(50,50,50)'};

class AdminUserDetail extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const user = this.props.user;
        if (!user) {
            return null;
        }


        const orders = user.orders.map((item, i) => (
            <table key={i}>
                <tbody>
                    <tr>
                        <td style={tableItemStyle}>
                            Order number
                        </td>
                        <td>
                            {item._id}
                        </td>
                    </tr>
                    <tr>
                        <td style={tableItemStyle}>
                            Timestamp
                        </td>
                        <td>
                            {item.timestamp}
                        </td>
                    </tr>
                    <tr>
                        <td style={tableItemStyle}>
                            Items
                        </td>
                            <td>
                                {item.items.map((orderItem, index) => (
                                <tr>
                                    <td>
                                        {orderItem.name}
                                    </td>
                                    <td>
                                        {`${orderItem.quantity}`}
                                    </td>
                                    <td>
                                        {`${orderItem.currency} ${orderItem.amount}`}
                                    </td>
                                </tr>
                            ))}
                        </td>
                    </tr>
                    <tr>
                        <td style={tableItemStyle}>
                            Total
                        </td>
                        <td>
                            {`${item.items.length > 0 ? item.items[0].currency : null} ${item.total}`}
                        </td>
                    </tr>
                </tbody>
            </table>
        ));


        const ownedProducts = user.ownedProducts.map((item, i) => (
            <table key={i}>
                <tbody>
                    <tr>
                        <td style={tableItemStyle}>
                            Product
                        </td>
                        <td>
                            {item.name}
                        </td>
                    </tr>
                    <tr>
                        <td style={tableItemStyle}>
                            Serial Number
                        </td>
                        <td>
                            {item.serialNumber}
                        </td>
                    </tr>
                </tbody>
            </table>
        ))


        return (
            <div>
                <div>
                    <h3>Basic Info</h3>
                    <table>
                        <tbody>
                            <tr>
                                <td style={tableItemStyle}>
                                    Firstname
                                </td>
                                <td>
                                    {user.firstName ? user.firstName : ""}
                                </td>
                            </tr>
                            <tr>
                                <td style={tableItemStyle}>
                                    LastName
                                </td>
                                <td>
                                    {user.lastName ? user.lastName : ""}
                                </td>
                            </tr>
                            <tr>
                                <td style={tableItemStyle}>
                                    Email
                                </td>
                                <td>
                                    {user.email}
                                </td>
                            </tr>
                            <tr>
                                <td style={tableItemStyle}>
                                    User_id
                                </td>
                                <td>
                                    {user._id}
                                </td>
                            </tr>
                            <tr>
                                <td style={tableItemStyle}>
                                    Stipe_customer_id
                                </td>
                                <td>
                                    {user.customerID ? user.customerID : ""}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <h3>Shipping Address</h3>
                    <table>
                        <tbody>
                            <tr>
                                <td style={tableItemStyle}>
                                    To
                                </td>
                                <td>
                                    {(user.address && user.address.name) ? user.address.name : ""}
                                </td>
                            </tr>
                            <tr>
                                <td style={tableItemStyle}>
                                    Email
                                </td>
                                <td>
                                    {(user.address && user.address.email) ? user.address.email : ""}
                                </td>
                            </tr>
                            <tr>
                                <td style={tableItemStyle}>
                                    Line1
                                </td>
                                <td>
                                    {(user.address && user.address.line1) ? user.address.line1 : ""}
                                </td>
                            </tr>
                            <tr>
                                <td style={tableItemStyle}>
                                    Line2
                                </td>
                                <td>
                                    {(user.address && user.address.line2) ? user.address.line2 : ""}
                                </td>
                            </tr>
                            <tr>
                                <td style={tableItemStyle}>
                                    City
                                </td>
                                <td>
                                    {(user.address && user.address.city) ? user.address.city : ""}
                                </td>
                            </tr>
                            <tr>
                                <td style={tableItemStyle}>
                                    State
                                </td>
                                <td>
                                    {(user.address && user.address.state) ? user.address.state : ""}
                                </td>
                            </tr>
                            <tr>
                                <td style={tableItemStyle}>
                                    Country
                                </td>
                                <td>
                                    {(user.address && user.address.postal_code) ? user.address.postal_code : ""}
                                </td>
                            </tr>
                            <tr>
                                <td style={tableItemStyle}>
                                    Country
                                </td>
                                <td>
                                    {(user.address && user.address.country) ? user.address.country : ""}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <h3>Orders</h3>
                    {orders}
                </div>
                <div>
                    <h3>Owned Products</h3>
                    {ownedProducts}
                </div>
            </div>
        )
    }
}

export default AdminUserDetail;