import React, {Component} from 'react'
import CouponTabStyle from './adminCouponTab.module.css'
import axios from 'axios';
import { API_URL, products } from '../../Global_Config';
import { TableRow, TableCell, Table, TableHead, TableBody, TextField, Grid, Button, Typography, Dialog, FormControlLabel, Checkbox, MenuItem } from '@material-ui/core';

const tableItemStyle = {fontWeight: 300, color: 'rgb(50,50,50)'};

class AdminCouponTab extends Component {
    constructor(props){
        super(props);
        this.state = {
            coupons: [],
            filteredCoupons: [],
            selectedRow: -1,
            filter: 'all',
            searchTerm: '',
            isAddingNewCoupon: false,
            dataFetched: false,
        }
    }

    componentDidMount() {
        if (this.props.isAuthenticated && !this.state.dataFetched) {
            this.getAllCoupons();
        }
    }

    componentDidUpdate() {
        if (this.props.isAuthenticated && !this.state.dataFetched) {
            this.getAllCoupons();
        }
    }


    componentWillUnmount() {
        this.clearSelection();
        this.setState({ searchTerm: '', dataFetched: false })
    }

    getAllCoupons = async() => {
        if (typeof window !== 'undefined') {
            const token = localStorage.getItem('token');
            let config = { 
                headers: { 
                    token: token,
                },
            };
            try {
                const res = await axios.get(`${API_URL}/admin/promocode`, config)
                this.setState({coupons: res.data, filteredCoupons: res.data, selectedRow: -1, dataFetched: true, filter:'all'});
            } catch (err) {
                alert(err.response.data.message);
            }
        }
    }

    clearSelection = () => {
        this.setState({selectedRow: -1});
    }

    handleSearch = (evt) => {
        if (this.state.coupons.length === 0) {
            return;
        }
        evt.persist();
        const terms = evt.target.value.toLowerCase().split(' ').join('');
        
        const result = this.state.coupons.filter((item) => {
            const content = (item.code).toLowerCase();
            return content.includes(terms);
        })
        this.setState(state => ({...state, filteredCoupons: result}));
    }

    handleFilter = (condition) => event => {
        if (this.state.coupons.length === 0) {
            this.setState(state => ({...state, filter: condition}));
            return;
        }
        if (condition === 'available') {
            const result = this.state.coupons.filter((item) => {
                return Boolean(item.isValid);
            })
            this.setState(state => ({...state, filteredCoupons: result, filter: condition}));
        } else if (condition === 'expired') {
            const result = this.state.coupons.filter((item) => {
                return !Boolean(item.isValid);;
            })
            this.setState(state => ({...state, filteredCoupons: result, filter: condition}));
        } else if (condition === 'all') {
            this.setState(state => ({...state, filteredCoupons: this.state.coupons, filter: condition}));
        } 
    }

    handleSelection = (row) => {
        this.setState({selectedRow: this.state.selectedRow == row ? -1 : row, editorState: undefined, emailSubject: ''});
    }

    handleInput = name => event => {
        event.persist();
        this.setState(state => ({ ...state, [name]: event.target.value, [`${name}Error`]: false }));
    };

    openNewCouponPopover = () => {
        this.setState(state=> ({...state, isAddingNewCoupon: true}));
    }

    closeNewCouponPopover = () => {
        this.setState(state=> ({...state, isAddingNewCoupon: false}));
    }



    handleAddNewCoupon = async() => {
        let err = false;
        if (!this.state.newDiscount) {
            this.setState({newDiscountError: true});
            err = true;
        }
        if (!this.state.newCode)  {
            this.setState({newCodeError: true});
            err = true;
        }
        if (!this.state.newTarget) {
            this.setState({newTargetError:true});
            err = true;
        }
        if (!this.state.newExpiration) {
            this.setState({newExpirationError: true});
            err = true;
        }
        if (err) {
            return;
        }
        if (typeof window !== 'undefined') {
            const token = localStorage.getItem('token');
            let config = { 
                headers: { 
                    token: token,
                },
            };
            const obj = {
                code: this.state.newCode.toUpperCase(),
                content: {
                    discount: this.state.newDiscount,
                    target: this.state.newTarget,
                },
                description: this.state.newDescription,
                expireAt: this.state.newExpiration,
                availability: this.state.newAvailability ? this.state.newAvailability : 999,
            }
            try {
                const res = await axios.post(`${API_URL}/admin/promocode`, obj, config)
                this.closeNewCouponPopover();
                alert('Coupon added');
                // console.log(res);
                this.getAllCoupons();
                // this.setState({coupons: res.data, filteredCoupons: res.data, selectedRow: -1});
            } catch (err) {
                alert(err.response.data.message);
            }
        }
    }

    handleDelete = async() => {
        if (!confirm("Are you sure to delete this coupon?")) {
            return;
        }
        const selectedCoupon = this.state.filteredCoupons[this.state.selectedRow];
        if (selectedCoupon) {
            if (typeof window !== 'undefined') {
                const token = localStorage.getItem('token');
                let config = { 
                    headers: { 
                        token: token,
                    },
                };
                try {
                    await axios.delete(`${API_URL}/promocode/${selectedCoupon.code}`, config)
                    alert('Coupon deleted');
                    // console.log(res);
                    this.getAllCoupons();
                    // this.setState({users: res.data, filteredUser: res.data, selectedRow: -1});
                } catch (err) {
                    alert(err.response.data.message);
                }
            }
        }
    }

    

    render() {
        if (!this.state.dataFetched) {
            return null;
        }
        
        const selectedCoupon = this.state.filteredCoupons[this.state.selectedRow];

        const newCouponPopover = (
            <Dialog
                className={CouponTabStyle.modal}
                open = {this.state.isAddingNewCoupon}
                onClose = {this.closeNewCouponPopover}
            >
                <div className={CouponTabStyle.addNewModalContainer}>
                    <h3>New Coupon</h3>
                    <table>
                        <tbody>
                            <tr>
                                <td style={tableItemStyle}>
                                    Code
                                </td>
                                <td className={CouponTabStyle.newCode}>
                                    <TextField placeholder="Promo code" value={this.state.newCode} error={this.state.newCodeError} required onChange={this.handleInput('newCode')} fullWidth />
                                </td>
                            </tr>
                            <tr>
                                <td style={tableItemStyle}>
                                    Discount
                                </td>
                                <td>
                                    <TextField placeholder={`eg. "30" for $30 off, "30%" for 30% off`} value={this.state.newDiscount} error={this.state.newDiscountError} required onChange={this.handleInput('newDiscount')} fullWidth />
                                </td>
                            </tr>
                            <tr>
                                <td style={tableItemStyle}>
                                    Target
                                </td>
                                <td>
                                    <TextField placeholder="Target" value={this.state.newTarget} error={this.state.newTargetError} select required onChange={this.handleInput('newTarget')} fullWidth>
                                        {products.map((item, i) => {
                                            return (<MenuItem key={i} value={item.name.toLowerCase()}>{item.name}</MenuItem>)
                                        })}
                                        <MenuItem value="shipping">Shipping</MenuItem>
                                    </TextField>
                                </td>
                            </tr>
                            <tr>
                                <td style={tableItemStyle}>
                                    Description
                                </td>
                                <td>
                                    <TextField placeholder="Description" value={this.state.newDescription} error={this.state.newDescriptionError} required onChange={this.handleInput('newDescription')} fullWidth />
                                </td>
                            </tr>
                            <tr>
                                <td style={tableItemStyle}>
                                    Expire at
                                </td>
                                <td>
                                    <TextField placeholder="YYYY-MM-DDTHH:MM:SS (UTC)" value={this.state.newExpiration} error={this.state.newExpirationError} required onChange={this.handleInput('newExpiration')} fullWidth />
                                </td>
                            </tr>
                            <tr>
                                <td style={tableItemStyle}>
                                    Availability
                                </td>
                                <td>
                                    <TextField placeholder="Default 999" value={this.state.newAvailability} error={this.state.newAvailabilityError} onChange={this.handleInput('newAvailability')} fullWidth />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{textAlign: 'center', marginTop:'64px'}}>
                        <Button variant='contained' color='primary' onClick={this.handleAddNewCoupon}>Add</Button>
                    </div>
                </div>
            </Dialog>
        )


        const coupons = this.state.filteredCoupons.map((item, i) => {
            return (
                <TableRow key={i} onClick={evt=>this.handleSelection(i)} selected={this.state.selectedRow == i}>
                    <TableCell>{item.code}</TableCell>
                    <TableCell>{item.content.discount}</TableCell>
                    <TableCell>{item.content.target}</TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell>{item.time_used}</TableCell>
                    <TableCell>{item.availability}</TableCell>
                    <TableCell>{item.expireAt}</TableCell>
                </TableRow>
            )
        })

        return (
            <div>
                <div className={CouponTabStyle.toolbar}>
                    <div className={CouponTabStyle.searchBar}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <TextField placeholder="Search" onChange={this.handleSearch}  style={{margin: '12px'}}/>
                            </Grid>
                            <Grid item xs>
                                <FormControlLabel control={<Checkbox checked={this.state.filter==='all'} onChange={this.handleFilter('all')} value='all' />} label='All' />
                            </Grid>
                            <Grid item xs>
                                <FormControlLabel control={<Checkbox checked={this.state.filter==='available'} onChange={this.handleFilter('available')} value='available' />} label='Available' />
                            </Grid>
                            <Grid item xs>
                                <FormControlLabel control={<Checkbox checked={this.state.filter==='expired'} onChange={this.handleFilter('expired')} value='expired' />} label='Expired' />
                            </Grid>
                        </Grid>
                    </div>
                </div>
                {this.state.selectedRow > -1 ? 
                    <div style={{ marginTop: '12px', textAlign: 'center'}}>
                        <Typography variant='h4'>{selectedCoupon ? selectedCoupon.code:null}</Typography>
                        <br/>
                        <Button color='secondary' onClick={this.handleDelete}>Delete Coupon</Button>
                    </div>
                : null}
                {this.state.selectedRow < 0 ?
                <div style={{ marginTop: '12px', textAlign: 'center'}}>
                    <Button color='primary' onClick={this.openNewCouponPopover}>Add Coupon</Button>
                </div>
                : null }
                <Table className={CouponTabStyle.userTable} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Code</TableCell>
                        <TableCell>Discont</TableCell>
                        <TableCell>Target</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Number of times used</TableCell>
                        <TableCell>Availability</TableCell>
                        <TableCell>Expire At</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {coupons}
                </TableBody>
                </Table>
                <p style={{fontSize: '14px', textAlign: 'center'}}>{this.state.filteredCoupons.length} coupons</p>
                {newCouponPopover}
            </div>
        )
    }
}

export default AdminCouponTab;