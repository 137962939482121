import React, {Component} from 'react'
import ProductTabStyle from './adminProductTab.module.css'
import axios from 'axios';
import { API_URL } from '../../Global_Config';
import { TableRow, TableCell, Table, TableHead, TableBody, TextField, Grid, Button, Typography, Dialog, FormControlLabel, Checkbox, MenuItem } from '@material-ui/core';


const tableItemStyle = {fontWeight: 300, color: 'rgb(50,50,50)'};

class AdminProductTab extends Component {
    constructor(props){
        super(props);
        this.state = {
            products: [],
            filteredProducts: [],
            selectedRow: -1,
            filter: 'all',
            selectedProductDetail: null,
            searchTerm: '',
            isAddingNewProduct: false,
            dataFetched: false,
            isRegisteringProduct: false,
        }
    }

    componentDidMount() {
        if (this.props.isAuthenticated && !this.state.dataFetched) {
            this.getAllProducts();
        }
    }

    componentDidUpdate() {
        if (this.props.isAuthenticated && !this.state.dataFetched) {
            this.getAllProducts();
        }
    }


    componentWillUnmount() {
        this.clearSelection();
        this.setState({ searchTerm: '', dataFetched: false })
    }

    getAllProducts = async() => {
        if (typeof window !== 'undefined') {
            const token = localStorage.getItem('token');
            let config = { 
                headers: { 
                    token: token,
                },
            };
            try {
                const res = await axios.get(`${API_URL}/products`, config)
                // console.log(res);
                this.setState({products: res.data, filteredProducts: res.data, selectedRow: -1, dataFetched: true});
            } catch (err) {
                alert(err.response.data.message);
            }
        }
    }

    clearSelection = () => {
        this.setState({selectedRow: -1});
    }

    handleSearch = (evt) => {
        if (this.state.products.length === 0) {
            return;
        }
        evt.persist();
        const terms = evt.target.value.toLowerCase().split(' ').join('');
        
        const result = this.state.products.filter((item) => {
            const content = (item.name+item.serialNumber).toLowerCase();
            return content.includes(terms);
        })
        this.setState(state => ({...state, filteredProducts: result}));
    }

    handleFilter = (condition) => event => {
        if (this.state.products.length === 0) {
            this.setState(state => ({...state, filter: condition}));
            return;
        }
        if (condition === 'sold') {
            const result = this.state.products.filter((item) => {
                return Boolean(item.owner);
            })
            this.setState(state => ({...state, filteredProducts: result, filter: condition}));
        } else if (condition === 'available') {
            const result = this.state.products.filter((item) => {
                return !Boolean(item.owner);;
            })
            this.setState(state => ({...state, filteredProducts: result, filter: condition}));
        } else if (condition === 'all') {
            this.setState(state => ({...state, filteredProducts: this.state.products, filter: condition}));
        } 
    }

    handleSelection = (row) => {
        this.setState({selectedRow: this.state.selectedRow == row ? -1 : row, editorState: undefined, emailSubject: ''});
    }

    handleInput = name => event => {
        event.persist();
        this.setState(state => ({ ...state, [name]: event.target.value, [`${name}Error`]: false }));
    };

    openNewProductPopover = () => {
        this.setState(state=> ({...state, isAddingNewProduct: true}));
    }

    closeNewProductPopover = () => {
        this.setState(state=> ({...state, isAddingNewProduct: false}));
    }

    openRegisterPopover = () => {
        this.setState(state=> ({...state, isRegisteringProduct: true}));
    }

    closeRegisterPopover = () => {
        this.setState(state=> ({...state, isRegisteringProduct: false}));
    }



    hash = (str) => {
        var hash = 5381,
            i    = str.length;
        
        while(i) {
            hash = (hash * 33) ^ str.charCodeAt(--i);
        }
        return hash >>> 0;
    }

    handleAddNewProducts = async() => {
        let products = [];
        const {newProductName, newProductBatch, newProductUnit ,newProductQuantity} = this.state;
        let err = false;
        if (!this.props.user_id) {
            alert("Not logged in");
            return;
        }
        if (!newProductName) {this.setState({newProductNameError: true}); err=true};
        if (!newProductBatch || isNaN(newProductBatch) || newProductBatch == 0) {this.setState({newProductBatchError: true}); err=true};
        if (!newProductUnit || isNaN(newProductUnit) || newProductUnit == 0) {this.setState({newProductUnitError: true}); err=true};
        if (!newProductQuantity || isNaN(newProductQuantity) || newProductQuantity == 0) {this.setState({newProductQuantityError: true}); err=true};
        if (err) {return;};
        for (let i=0; i<newProductQuantity;i++) {
            const productOBJ = {
                name: newProductName,
                batchNumber: Number(newProductBatch),
                unitNumber: Number(newProductUnit)+i,
                serialNumber: this.hash(`${newProductName}${newProductBatch}${Number(newProductUnit)+i}`),
                createdBy: this.props.user_id,
            }
            products.push(productOBJ);
        }
        if (typeof window !== 'undefined') {
            const token = localStorage.getItem('token');
            let config = { 
                headers: { 
                    token: token,
                },
            };
            try {
                const res = await axios.post(`${API_URL}/admin/products`, {'units': products}, config)
                this.closeNewProductPopover();
                alert(res.data);
                // console.log(res);
                this.getAllProducts();
                // this.setState({users: res.data, filteredUser: res.data, selectedRow: -1});
            } catch (err) {
                alert(err.response.data.message);
            }
        }
    }

    handleDeleteProduct = async() => {
        if (!confirm("Are you sure to delete this product?")) {
            return;
        }
        const selectedProduct = this.state.filteredProducts[this.state.selectedRow];
        if (selectedProduct) {
            if (typeof window !== 'undefined') {
                const token = localStorage.getItem('token');
                let config = { 
                    headers: { 
                        token: token,
                    },
                };
                try {
                    await axios.delete(`${API_URL}/products/${selectedProduct._id}`, config)
                    alert('Product deleted');
                    // console.log(res);
                    this.getAllProducts();
                    // this.setState({users: res.data, filteredUser: res.data, selectedRow: -1});
                } catch (err) {
                    alert(err.response.data.message);
                }
            }
        }
    }

    handleUnregister = async() => {
        if (!confirm("Are you sure to unregister this product from owner?")) {
            return;
        }
        const selectedProduct = this.state.filteredProducts[this.state.selectedRow];
        if (selectedProduct) {
            if (typeof window !== 'undefined') {
                const token = localStorage.getItem('token');
                let config = { 
                    headers: { 
                        token: token,
                    },
                };
                try {
                    await axios.put(`${API_URL}/admin/unregisterProduct`, {product_id: selectedProduct._id}, config)
                    alert('Product unregistered');
                    // console.log(res);
                    this.getAllProducts();
                    // this.setState({users: res.data, filteredUser: res.data, selectedRow: -1});
                } catch (err) {
                    alert(err.response.data.message);
                }
            }
        }
    }

    handleRegister = async() => {
        if (!this.state.newOwner) {
            this.setState({newOwnerError: true});
            return;
        }
        const selectedProduct = this.state.filteredProducts[this.state.selectedRow];
        if (selectedProduct) {
            if (typeof window !== 'undefined') {
                const token = localStorage.getItem('token');
                let config = { 
                    headers: { 
                        token: token,
                    },
                };
                try {
                    await axios.put(`${API_URL}/admin/registerProduct`, {product_id: selectedProduct._id, user_id: this.state.newOwner}, config)
                    alert('Product registered');
                    this.closeRegisterPopover();
                    this.getAllProducts();
                } catch (err) {
                    alert(err.response.data.message);
                }
            }
        }
    }

    render() {
        if (!this.state.dataFetched) {
            return null;
        }
        
        const selectedProduct = this.state.filteredProducts[this.state.selectedRow];

        const newProductsPopover = (
            <Dialog
                className={ProductTabStyle.modal}
                open = {this.state.isAddingNewProduct}
                onClose = {this.closeNewProductPopover}
            >
                <div className={ProductTabStyle.addNewModalContainer}>
                    <h3>New Products</h3>
                    <table>
                        <tbody>
                            <tr>
                                <td style={tableItemStyle}>
                                    Product Name
                                </td>
                                <td>
                                    <TextField placeholder="Name" value={this.state.newProductName} error={this.state.newProductNameError} select required onChange={this.handleInput('newProductName')} fullWidth>
                                        <MenuItem value="MO-Band">MO-Band</MenuItem>
                                        <MenuItem value="MO-Band Pro">MO-Band Pro</MenuItem>
                                    </TextField>
                                </td>
                            </tr>
                            <tr>
                                <td style={tableItemStyle}>
                                    Batch Number
                                </td>
                                <td>
                                    <TextField placeholder="Batch" value={this.state.newProductBatch} error={this.state.newProductBatchError} required onChange={this.handleInput('newProductBatch')} fullWidth />
                                </td>
                            </tr>
                            <tr>
                                <td style={tableItemStyle}>
                                    Unit Number
                                </td>
                                <td>
                                    <TextField spellCheck={false} contentEditable={false} placeholder="Number for the first unit" value={this.state.newProductUnit} error={this.state.newProductUnitError} required onChange={this.handleInput('newProductUnit')} fullWidth />
                                </td>
                            </tr>
                            <tr>
                                <td style={tableItemStyle}>
                                    Number of Units
                                </td>
                                <td>
                                    <TextField spellCheck={false} contentEditable={false} placeholder="Quantity" value={this.state.newProductQuantity} error={this.state.newProductQuantityError} required onChange={this.handleInput('newProductQuantity')} fullWidth />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{textAlign: 'center', marginTop:'64px'}}>
                        <Button variant='contained' color='primary' onClick={this.handleAddNewProducts}>Add</Button>
                    </div>
                </div>
            </Dialog>
        )

        const registerProductPopover = (
            <Dialog
                className={ProductTabStyle.modal}
                open = {this.state.isRegisteringProduct}
                onClose = {this.closeRegisterPopover}
            >
                <div className={ProductTabStyle.registerModalContainer}>
                    <h3>Register Product</h3>
                    <table>
                        <tbody>
                            <tr>
                                <td style={tableItemStyle}>
                                    User_id
                                </td>
                                <td>
                                    <TextField placeholder="New owner" value={this.state.newOwner} error={this.state.newOwnerError} required onChange={this.handleInput('newOwner')} fullWidth />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div style={{textAlign: 'center', marginTop:'64px'}}>
                        <Button variant='contained' color='primary' onClick={this.handleRegister}>Register</Button>
                    </div>
                </div>
            </Dialog>
        )

        const products = this.state.filteredProducts.map((item, i) => {
            return (
                <TableRow key={i} onClick={evt=>this.handleSelection(i)} selected={this.state.selectedRow == i}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.serialNumber}</TableCell>
                    <TableCell>{item.batchNumber}</TableCell>
                    <TableCell>{item.unitNumber}</TableCell>
                    <TableCell>{item.owner ? item.owner.email : null}</TableCell>
                    <TableCell>{item.date_created.split("T")[0]}</TableCell>
                    <TableCell>{item.createdBy ? `${item.createdBy.firstName ? item.createdBy.firstName : item.cureatedBy._id} ${item.createdBy.lastName ? item.createdBy.lastName : null}` : null}</TableCell>
                </TableRow>
            )
        })

        return (
            <div>
                <div className={ProductTabStyle.toolbar}>
                    <div className={ProductTabStyle.searchBar}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <TextField placeholder="Search" onChange={this.handleSearch}  style={{margin: '12px'}}/>
                            </Grid>
                            <Grid item xs>
                                <FormControlLabel control={<Checkbox checked={this.state.filter==='all'} onChange={this.handleFilter('all')} value='all' />} label='All' />
                            </Grid>
                            <Grid item xs>
                                <FormControlLabel control={<Checkbox checked={this.state.filter==='sold'} onChange={this.handleFilter('sold')} value='sold' />} label='sold' />
                            </Grid>
                            <Grid item xs>
                                <FormControlLabel control={<Checkbox checked={this.state.filter==='available'} onChange={this.handleFilter('available')} value='available' />} label='Available' />
                            </Grid>
                        </Grid>
                    </div>
                </div>
                {this.state.selectedRow > -1 ? 
                    <div style={{ marginTop: '12px', textAlign: 'center'}}>
                        <Typography variant='h4'>{selectedProduct ? `${selectedProduct.name}(${selectedProduct.serialNumber})` : null}</Typography>
                        <br/>
                        <Button color='primary' onClick={this.openRegisterPopover}>Register to...</Button>
                        <Button color='primary' onClick={this.handleUnregister}>Unregister</Button>
                        <Button color='secondary' onClick={this.handleDeleteProduct}>Delete Product</Button>
                    </div>
                : null}
                {this.state.selectedRow < 0 ?
                <div style={{ marginTop: '12px', textAlign: 'center'}}>
                    <Button color='primary' onClick={this.openNewProductPopover}>Add Products</Button>
                </div>
                : null }
                <Table className={ProductTabStyle.userTable} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Serial Number</TableCell>
                        <TableCell>Batch Number</TableCell>
                        <TableCell>Unit Number</TableCell>
                        <TableCell>Owner</TableCell>
                        <TableCell>Birthday</TableCell>
                        <TableCell>Added By</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {products}
                </TableBody>
                </Table>
                <p style={{fontSize: '14px', textAlign: 'center'}}>{this.state.filteredProducts.length} products</p>
                {newProductsPopover}
                {registerProductPopover}
            </div>
        )
    }
}

export default AdminProductTab;