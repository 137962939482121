import React, {Component} from 'react'
import UserTabStyle from './adminUserTab.module.css'
import axios from 'axios';
import { API_URL } from '../../Global_Config';
import EmailPopover from './adminEmail';
import { TableRow, TableCell, Table, TableHead, TableBody, TextField, Grid, Button, Typography, Dialog, FormControlLabel, Checkbox } from '@material-ui/core';
import { sendEmail } from '../../Global_Config';
import AdminUserDetail from './adminUserDetail';

class AdminUserTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            filteredUser: [],
            selectedRow: -1,
            showEmailRecipient: false,
            emailRecipient: null,
            emailDialogOpen: false,
            emailSubject: '',
            editorState: undefined,
            filter: 'all',
            searchTerm: '',
            selectedUserDetail: null,
            dataFetched: false,
        }
    }


    componentDidMount() {
        if (this.props.isAuthenticated && !this.state.dataFetched) {
            this.getAllUsers();
        }
    }

    componentDidUpdate() {
        if (this.props.isAuthenticated && !this.state.dataFetched) {
            this.getAllUsers();
        }
    }


    componentWillUnmount() {
        this.clearSelection();
        this.setState({ searchTerm: '', dataFetched: false })
    }

    validateEmail = (email) => {
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regexp.test(email);
    }

    clearSelection = () => {
        this.setState({selectedRow: -1});
    }

    handleInput = name => event => {
        event.persist();
        this.setState(state => ({ ...state, [name]: event.target.value, [`${name}Error`]: false }));
    };

    handleSelection = (row) => {
        this.setState({selectedRow: this.state.selectedRow == row ? -1 : row, editorState: undefined, emailSubject: ''});
    }

    

    openEmailPopover = () => {
        this.setState({emailDialogOpen: true, showEmailRecipient: false});
    }

    closeEmailPopover = () => {
        this.setState({emailDialogOpen: false, emailReceipient: null});
    }

    openUserDetailPopover = async(userId) => {
        if (typeof window !== 'undefined') {
            const token = localStorage.getItem('token');
            let config = { 
                headers: { 
                    token: token,
                },
            };
            try {
                const res = await axios.get(`${API_URL}/admin/users/${userId}`, config)
                this.setState({selectedUserDetail: res.data});
                // this.setState({users: res.data, filteredUser: res.data, selectedRow: -1});
            } catch (err) {
                alert(err.response.data.message);
            }
        }
    }

    closeUserDetailPopover = () => {
        this.setState({selectedUserDetail: null});
    }

    handleEmailAll = () => {
        this.setState({selectedRow: -1, emailDialogOpen: true, showEmailRecipient: false})
    }

    handleEmailTo = () => {
        this.setState({ showEmailRecipient: true, emailDialogOpen: true });
    }

    handleSendMail = async() => {
        if (this.state.showEmailRecipient) {
            let error = false
            if (!this.state.emailSubject) {
                this.setState({emailSubjectError: true});
                error = true;
            }
            if (!this.validateEmail(this.state.emailReceipient)) {
                this.setState({emailReceipientError: true});
                error = true;
            }
            if (error) {
                return;
            }
            const result = await sendEmail(this.state.emailReceipient, this.state.emailSubject, this.state.editorState);
            alert(result ? "Email sent" : "Email failed");
            if (result) {
                this.closeEmailPopover();
            }
        } else {
            const selectedUser = this.state.filteredUser[this.state.selectedRow];
            if (selectedUser) {
                if (!this.state.emailSubject) {
                    this.setState({emailSubjectError: true});
                    return;
                }
                const result = await sendEmail(selectedUser.email, this.state.emailSubject, this.state.editorState);
                alert(result ? "Email sent" : "Email failed");
                if (result) {
                    this.closeEmailPopover();
                }
            } else {
                if (!this.state.emailSubject) {
                    this.setState({emailSubjectError: true});
                    return;
                }
                var sent = 0;
                for (let i=0; i<filteredUser.length; i++) {
                    const result = sendEmail(this.state.filteredUser[i].email, this.state.emailSubject, this.state.editorState);
                    if (result) {
                        sent += 1;
                    }
                }
                alert(`${sent} emails sent`);
                this.closeEmailPopover();
            }
        }
        
        
    }

    


    handleTextEditorChange = (evt, editor) => {
        const data = editor.getData();
        this.setState({editorState: data});
    }

    getAdminTitle = () => {
        switch (this.props.user.firstName) {
            case "Rex":
                return "Co-founder & CEO";
            case "Alicia":
                return "Co-founder & Marketing Representative";
            case "Alan":
                return "Co-founder & Electrical Engineer";
            case "Joe":
                return "Co-founder & Software Engineer";
        }
    }

    handleSearch = (evt) => {
        if (this.state.users.length === 0) {
            return;
        }
        evt.persist();
        this.setState(state => ({...state, selectedRow: -1, editorState: undefined, emailSubject: ''}));
        const terms = evt.target.value.toLowerCase().split(' ').join('');
        
        const result = this.state.users.filter((item) => {
            const content = (item.firstName+item.lastName+item.email).toLowerCase();
            return content.includes(terms);
        })
        this.setState(state => ({...state, filteredUser: result}));
    }

    handleFilter = (condition) => event => {
        if (this.state.users.length === 0) {
            this.setState(state => ({...state, filter: condition}));
            return;
        }
        if (condition === 'mailist') {
            const result = this.state.users.filter((item) => {
                return item.username === 'SIMailListUser';
            })
            this.setState(state => ({...state, filteredUser: result, filter: condition}));
        } else if (condition === 'customer') {
            const result = this.state.users.filter((item) => {
                return item.username.includes('S-Customer');
            })
            this.setState(state => ({...state, filteredUser: result, filter: condition}));
        } else if (condition === 'all') {
            this.setState(state => ({...state, filteredUser: this.state.users, filter: condition}));
        } else if (condition === 'superuser') {
            const result = this.state.users.filter((item) => {
                return item.role === 'super';
            })
            this.setState(state => ({...state, filteredUser: result, filter: condition}));
        }
    }

    handleDeleteUser = async() => {
        const selectedUser = this.state.filteredUser[this.state.selectedRow];
        if ((typeof window !== 'undefined') && selectedUser) {
            if (!confirm(`Are you sure to delete user ${selectedUser.email}`)) {
                return;
            }
            const token = localStorage.getItem('token');
            let config = { 
                headers: { 
                    token: token,
                },
            };
            try {
                const res = await axios.delete(`${API_URL}/users/${selectedUser._id}`, config)
                alert('User deleted');
                this.getAllUsers()
            } catch (err) {
                alert(err.response.data.message);
            }
        }
        
    }

    getAllUsers = async() => {
        if (typeof window !== 'undefined') {
            const token = localStorage.getItem('token');
            let config = { 
                headers: { 
                    token: token,
                },
            };
            try {
                const res = await axios.get(`${API_URL}/admin/users`, config)
                // console.log(res);
                this.setState({users: res.data, filteredUser: res.data, selectedRow: -1, dataFetched: true});
            } catch (err) {
                alert(err.response.data.message);
            }
        }
    }

    getUser = async() => {
        if (typeof window !== 'undefined') {
            const token = localStorage.getItem('token');
            let config = { 
                headers: { 
                    token: token,
                },
            };
            try {
                const res = await axios.get(`${API_URL}/admin/users`, config)
                // console.log(res);
                this.setState({users: res.data, filteredUser: res.data, selectedRow: -1});
            } catch (err) {
                alert(err.response.data.message);
            }
        }
    }

    render() {
        const selectedUser = this.state.filteredUser[this.state.selectedRow];
        const users = this.state.filteredUser.map((item,i) => {
            return (
                <TableRow key={i} onClick={evt=>this.handleSelection(i)} selected={this.state.selectedRow == i}>
                    <TableCell>{`${item.firstName} ${item.lastName}`}</TableCell>
                    <TableCell>{item._id}</TableCell>
                    <TableCell>{item.email}</TableCell>
                    <TableCell>{item.customerID}</TableCell>
                    <TableCell>{item.role}</TableCell>
                </TableRow>
            )
        })

        const emailPopover = (
            <Dialog 
                className={UserTabStyle.modal}
                disableEnforceFocus
                open = {this.state.emailDialogOpen}
                onClose = {this.closeEmailPopover}
            >
                <div className={UserTabStyle.emailModalContainer}>
                    <div style={{marginBottom: '2em'}}>
                        {this.state.showEmailRecipient ? 
                            <TextField placeholder="Recipient" value={this.state.emailReceipient} error={this.state.emailReceipientError} required onChange={this.handleInput('emailReceipient')} fullWidth />
                            : 
                            <h3>{this.state.selectedRow === -1 ? 'To: All' : `To: ${selectedUser.email}`}</h3>
                        }
                        <TextField placeholder="Subject" value={this.state.emailSubject} error={this.state.emailSubjectError} required onChange={this.handleInput('emailSubject')} fullWidth />
                    </div>
                    <EmailPopover 
                        // to = {selectedUser ? `${selectedUser.firstName} ${selectedUser.lastName}` : undefined}
                        to = {selectedUser ? selectedUser.firstName : undefined}
                        state = {this.state.editorState}
                        name = {`${this.props.user.firstName} ${this.props.user.lastName}`}
                        title = {this.getAdminTitle()}
                        email = {this.props.user.email}
                        onChange={this.handleTextEditorChange}
                    />
                    <div style={{marginTop: '2em', textAlign:'center'}}>
                        <Button variant='contained' color='primary' onClick={this.handleSendMail}>Send</Button>
                    </div>
                </div>
            </Dialog>
        )

        const userDetailPopover = (
            <Dialog 
                className={UserTabStyle.modal}
                open = {Boolean(this.state.selectedUserDetail)}
                onClose = {this.closeUserDetailPopover}
            >
                <div className={UserTabStyle.userModalContainer}>
                    <AdminUserDetail user={this.state.selectedUserDetail}/>
                    <div style={{marginTop: '2em', textAlign:'center'}}>
                        <Button variant='contained' color='primary' onClick={this.closeUserDetailPopover}>OK</Button>
                    </div>
                </div>
            </Dialog>
        )


        const userContainer = (
            <div className={UserTabStyle.userContainer}>
                <div className={UserTabStyle.toolbar}>
                    <div className={UserTabStyle.searchBar}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <TextField placeholder="Search" onChange={this.handleSearch}  style={{margin: '12px'}}/>
                            </Grid>
                            <Grid item xs>
                                <FormControlLabel control={<Checkbox checked={this.state.filter==='all'} onChange={this.handleFilter('all')} value='all' />} label='All' />
                            </Grid>
                            <Grid item xs>
                                <FormControlLabel control={<Checkbox checked={this.state.filter==='mailist'} onChange={this.handleFilter('mailist')} value='mailist' />} label='Mailister' />
                            </Grid>
                            <Grid item xs>
                            <FormControlLabel control={<Checkbox checked={this.state.filter==='customer'} onChange={this.handleFilter('customer')} value='customer' />} label='Customer' />
                            </Grid>
                            <Grid item xs>
                                <FormControlLabel control={<Checkbox checked={this.state.filter==='superuser'} onChange={this.handleFilter('superuser')} value='superuser' />} label='Super User' />
                            </Grid>
                        </Grid>
                    </div>
                </div>
                {this.state.selectedRow > -1 ? 
                        <div style={{ marginTop: '12px', textAlign: 'center'}}>
                            <Typography variant='h4'>{selectedUser ? `${selectedUser.firstName} ${selectedUser.lastName}` : null}</Typography>
                            <br/>
                            <Button color='primary' onClick={this.openEmailPopover}>Send Email</Button>
                            <Button color='primary' onClick={() => this.openUserDetailPopover(selectedUser._id)}>Check Detail</Button>
                            <Button color='secondary' onClick={this.handleDeleteUser}>Delete User</Button>
                        </div>
                    : null}
                    {this.state.selectedRow < 0 ?
                    <div style={{ marginTop: '12px', textAlign: 'center'}}>
                        <Button color='primary' onClick={this.handleEmailAll}>Email All</Button>
                        <Button color='primary' onClick={this.handleEmailTo}>Email to</Button>
                    </div>
                    : null }
                <Table className={UserTabStyle.userTable} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>ID</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Stripe ID</TableCell>
                            <TableCell>Role</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users}
                    </TableBody>
                </Table>
                <p style={{fontSize: '14px', textAlign: 'center'}}>{this.state.filteredUser.length} users</p>
                {emailPopover}
                {userDetailPopover}
            </div>
        )
        return (
            <div className={UserTabStyle.infoContainer}>
                {userContainer}
            </div>
        )
    }
}

export default AdminUserTab