import React, { Component } from "react"
import TextEditor from './textEditor'

class EmailPopover extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { to, name, title, email, onChange, state} = this.props;
        const template = `
        ${to ? `
        <p>Hi ${to},` : ''}</p>
        <p>&nbsp;</p>
        <p style='font-weight:700'>${name}</p>
        <p>${title}<br>
        Sonic Instruments Corp.<br>
        ${email}</p>
        `
        return (
            <div>
                <TextEditor 
                        to={to} 
                        name = {name}
                        title = {title}
                        email = {email}
                        onChange={onChange}
                        data = {state ? state : template}
                    />
            </div>
        )
    }
}

export default EmailPopover;