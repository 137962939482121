import React, { Component } from "react"
import TextEditorStyle from './textEditor.module.css';

class TextEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {isServer: true};
    }

    componentDidMount() {
        this.CKEditor = require('@ckeditor/ckeditor5-react');
        this.ClassicEditor = require('@ckeditor/ckeditor5-build-classic');
        this.setState({isServer: false});
    }


    render() {
        return (
            <div className={TextEditorStyle.container} onClick={this.focusEditor}>
                {this.CKEditor && (<this.CKEditor
                    editor={ this.ClassicEditor }
                    data={this.props.data}
                    // onChange={ ( event, editor ) => {
                    //     const data = editor.getData();
                    //     console.log( { event, editor, data } );
                    // } }
                    onChange={this.props.onChange}
                />)
                }
            </div>
        )
    }
}

export default TextEditor