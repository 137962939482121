import React, { Component } from 'react';
import { connect } from 'react-redux';
import Layout from '../components/Layout';
import AdminStyle from './admin.module.css';
import { Tabs, Tab } from '@material-ui/core';
import { navigate } from 'gatsby';

import AdminUserTab from '../components/adminUserTab';
import AdminProductTab from '../components/adminProductTab';
import AdminSoftwareTab from '../components/adminSoftwareTab';
import AdminCouponTab from '../components/adminCouponTab';

class Admin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: 0,
        }
    }

    componentDidMount() {
        if (!this.isSuperOrAdmin()) {
            navigate('/');
        }
    }


    componentDidUpdate() {
        if (!this.isSuperOrAdmin()) {
            navigate('/');
        } 
    }

    

    isSuperOrAdmin = () => {
        const role = this.props.auth.role;
        return (this.props.auth.isLoggedIn && (role === 'super' || role === 'admin'));
    }

    handleTabChange = (evt, newValue) => {
        this.setState(state => ({...state, tab: newValue}))
    }


    render() {
        if (!this.isSuperOrAdmin()) {
            return null;
        }

        return (
            <Layout>
                <div className={AdminStyle.container}>
                    
                    <h2 style={{textAlign:'center'}}>Admin Dashboard</h2>
                    <div className={AdminStyle.tabBar}>
                        <Tabs value={this.state.tab} onChange={this.handleTabChange}>
                            <Tab label="Users" id="userTab" aria-controls='userTabPanel' />
                            <Tab label="Hardware" id="productTab" aria-controls='productTabPanel' />
                            <Tab label="Software" id="softwareTab" aria-controls='softwareTabPanel' />
                            <Tab label="Coupons" id="couponTab" aria-controls='couponTabPanel' />
                        </Tabs>
                    </div>
                    <div className={AdminStyle.infoContainer}>
                        {this.state.tab === 0 ? <AdminUserTab isAuthenticated={this.isSuperOrAdmin()} user={this.props.user}/> : null}
                        {this.state.tab === 1 ? <AdminProductTab isAuthenticated={this.isSuperOrAdmin()} user_id={this.props.user._id} /> : null}
                        {this.state.tab === 2 ? <AdminSoftwareTab isAuthenticated={this.isSuperOrAdmin()} user_id={this.props.user._id} /> : null}
                        {this.state.tab === 3 ? <AdminCouponTab isAuthenticated={this.isSuperOrAdmin()}/> : null}
                    </div>
                </div>
            </Layout>
        )
    }
}

const mapStatetoProps = (state) => {return { auth : state.auth, user: state.user.data }};
export default connect(mapStatetoProps, null)(Admin);