import React, {Component} from 'react'
import SoftwareTabStyle from './adminSoftwareTab.module.css'
import axios from 'axios';
import { API_URL } from '../../Global_Config';
import { TableRow, TableCell, Table, TableHead, TableBody, TextField, Grid, Button, Typography, Dialog, FormControlLabel, Checkbox, MenuItem } from '@material-ui/core';

const tableItemStyle = {fontWeight: 300, color: 'rgb(50,50,50)'};

class AdminSoftwareTab extends Component {
    constructor(props){
        super(props);
        this.state = {
            softwareList: [],
            filteredSoftware: [],
            selectedRow: -1,
            filter: 'all',
            searchTerm: '',
            dataFetched: false,
            isRegisteringSoftware: false,
            isGeneratingSN: false,
        }
    }

    componentDidMount() {
        if (this.props.isAuthenticated && !this.state.dataFetched) {
            this.getAllSoftware();
        }
    }

    componentDidUpdate() {
        if (this.props.isAuthenticated && !this.state.dataFetched) {
            this.getAllSoftware();
        }
    }


    componentWillUnmount() {
        this.clearSelection();
        this.setState({ searchTerm: '', dataFetched: false })
    }

    getAllSoftware = async() => {
        if (typeof window !== 'undefined') {
            const token = localStorage.getItem('token');
            let config = { 
                headers: { 
                    token: token,
                },
            };
            try {
                const res = await axios.get(`${API_URL}/software/admin/`, config)
                console.log(res);
                this.setState({softwareList: res.data, filteredSoftware: res.data, selectedRow: -1, dataFetched: true});
            } catch (err) {
                alert(err.response.data.message);
            }
        }
    }

    clearSelection = () => {
        this.setState({selectedRow: -1});
    }

    handleSearch = (evt) => {
        if (this.state.softwareList.length === 0) {
            return;
        }
        evt.persist();
        const terms = evt.target.value.toLowerCase().split(' ').join('');
        
        const result = this.state.softwareList.filter((item) => {
            const content = (item.name+item.owner.email).toLowerCase();
            return content.includes(terms);
        })
        this.setState(state => ({...state, filteredSoftware: result}));
    }

    handleFilter = (condition) => event => {
        if (this.state.softwareList.length === 0) {
            this.setState(state => ({...state, filter: condition}));
            return;
        }
        if (condition === 'sold') {
            const result = this.state.softwareList.filter((item) => {
                return Boolean(item.owner);
            })
            this.setState(state => ({...state, filteredSoftware: result, filter: condition}));
        } else if (condition === 'available') {
            const result = this.state.softwareList.filter((item) => {
                return !Boolean(item.owner);;
            })
            this.setState(state => ({...state, filteredSoftware: result, filter: condition}));
        } else if (condition === 'all') {
            this.setState(state => ({...state, filteredSoftware: this.state.softwareList, filter: condition}));
        } 
    }

    handleSelection = (row) => {
        this.setState({selectedRow: this.state.selectedRow == row ? -1 : row, editorState: undefined, emailSubject: ''});
    }

    handleInput = name => event => {
        event.persist();
        this.setState(state => ({ ...state, [name]: event.target.value, [`${name}Error`]: false }));
    };


    openGenSN = () => {
        this.setState(state => ({...state, isGeneratingSN: true}));
    }

    closeGenSN = () => {
        this.setState(state => ({...state, isGeneratingSN: false}));
    }

    hash = (str) => {
        var hash = 5381,
            i    = str.length;
        
        while(i) {
            hash = (hash * 33) ^ str.charCodeAt(--i);
        }
        return hash >>> 0;
    }


    handleDeleteProduct = async() => {
        if (!confirm("Are you sure to delete this license?")) {
            return;
        }
        const selectedSoftware = this.state.filteredSoftware[this.state.selectedRow];
        if (selectedSoftware) {
            if (typeof window !== 'undefined') {
                const token = localStorage.getItem('token');
                let config = { 
                    headers: { 
                        token: token,
                    },
                    data: {
                        softwareID: selectedSoftware._id
                    }
                };
                try {
                    await axios.delete(`${API_URL}/software/admin/`, config)
                    alert('License deleted');
                    // console.log(res);
                    this.getAllSoftware();
                    // this.setState({users: res.data, filteredUser: res.data, selectedRow: -1});
                } catch (err) {
                    alert(err.response.data.message);
                }
            }
        }
    }

    handleSendSN = async() => {
        this.setState(state => ({...state, newAuthCode: "", newAuthCodeDecoded: ""}));
        if (typeof window !== 'undefined') {
            const token = localStorage.getItem('token');
            let config = {
                headers: {
                    token: token,
                },
            };
            try {
                var softwareName = this.state.authLicenseName
                if (softwareName === "SB_STD") {
                    softwareName = "S-Board"
                }
                if (softwareName === "SB_DLX") {
                    softwareName = "S-Board Deluxe"
                }
                if (softwareName === "SB_CPLT") {
                    softwareName = "S-Board Complete"
                }
                const user = await axios.get(`${API_URL}/admin/users/${this.state.authUserID}`, config);
                let body = {email: user.data.email, softwareName: softwareName.toUpperCase(), licenseName: this.state.authLicenseName, authType: this.state.authLicenseType, expiresAt: this.state.authLicenseExpiration};
                const res = await axios.post(`${API_URL}/software/admin/sendSerialNumber`, body, config);
                this.setState(state => ({...state, newAuthCode: res.data}));
            } catch (err) {
                alert(err.response.data.message)
            }
        }
    }

    handleGenSN = async() => {
        this.setState(state => ({...state, newAuthCode: "", newAuthCodeDecoded: ""}));
        if (typeof window !== 'undefined') {
            const token = localStorage.getItem('token');
            let config = {
                headers: {
                    token: token,
                },
            };
            try {
                let body = {userID: this.state.authUserID, licenseName: this.state.authLicenseName, authType: this.state.authLicenseType, expiresAt: this.state.authLicenseExpiration};
                const res = await axios.post(`${API_URL}/software/genSerialNumber`, body, config);
                this.setState(state => ({...state, newAuthCode: res.data}));
            } catch (err) {
                alert(err.response.data.message)
            }
        }
    }

    handleDecodeSN = async() => {
        this.setState(state => ({...state, newAuthCodeDecoded: ""}));
        if (typeof window !== 'undefined') {
            const token = localStorage.getItem('token');
            let config = {
                headers: {
                    token: token,
                },
            };
            try {
                let body = {serialNumber: this.state.newAuthCode};
                const res = await axios.post(`${API_URL}/software/decryptSerialNumber`, body, config);
                this.setState(state => ({...state, newAuthCodeDecoded: JSON.stringify(res.data, undefined, 2)}));
            } catch (err) {
                alert(err.response.data.message)
            }
        }
    }

    render() {
        if (!this.state.dataFetched) {
            return null;
        }
        
        const selectedSoftware = this.state.filteredSoftware[this.state.selectedRow];

        const genSerialNumberPopover = (
            <Dialog
                className={SoftwareTabStyle.modal}
                open={this.state.isGeneratingSN}
                onClose = {this.closeGenSN}
            >
                <div className={SoftwareTabStyle.genSNModalContainer}>
                    <h3>Generate Serial Number</h3>
                    <table>
                        <tbody>
                            <tr>
                                <td style={tableItemStyle}>
                                    License Name
                                </td>
                                <td>
                                    <TextField placeholder="Licnese name" value={this.state.authLicenseName} error={this.state.authLicenseNameError} select required onChange={this.handleInput('authLicenseName')} fullWidth>
                                        <MenuItem value="SB_STD">S-Board Standard</MenuItem>
                                        <MenuItem value="SB_DLX">S-Board Delux</MenuItem>
                                        <MenuItem value="SB_CPLT">S-Board Complete</MenuItem>
                                        <MenuItem value="BOKEH">Bokeh</MenuItem>
                                        <MenuItem value="DIEROBOTER">dieRoboter</MenuItem>
                                    </TextField>
                                </td>
                            </tr>
                            <tr>
                                <td style={tableItemStyle}>
                                    User_id
                                </td>
                                <td>
                                    <TextField placeholder="User ID to authorize" value={this.state.authUserID} error={this.state.authUserIDError} required onChange={this.handleInput('authUserID')} fullWidth />
                                </td>
                            </tr>
                            <tr>
                                <td style={tableItemStyle}>
                                    License Type
                                </td>
                                <td>
                                    <TextField placeholder="License type" value={this.state.authLicenseType} select required error={this.state.authLicenseTypeError} required onChange={this.handleInput('authLicenseType')} fullWidth>
                                        <MenuItem value="Trial">Trial</MenuItem>
                                        <MenuItem value="Perpetual">Perpetual</MenuItem>
                                        <MenuItem value="NFR">NFR</MenuItem>
                                        <MenuItem value="Subscription">Subscription</MenuItem>
                                    </TextField>
                                </td>
                            </tr>
                            <tr>
                                <td style={tableItemStyle}>
                                    License Expiration Date
                                </td>
                                <td>
                                    <TextField placeholder="yyyy-mm-dd" value={this.state.authLicenseExpiration} error={this.state.authLicenseExpirationError} required onChange={this.handleInput('authLicenseExpiration')} fullWidth />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div>
                        <TextField placeholder="Response" multiline value={this.state.newAuthCode} onChange={this.handleInput('newAuthCode')} error={this.state.newAuthCodeError} fullWidth />
                    </div>
                    <div>
                        <TextField placeholder="Decoded" multiline value={this.state.newAuthCodeDecoded} error={this.state.newAuthCodeDecodedError} fullWidth />
                    </div>
                    <div style={{textAlign: 'center', marginTop:'32px'}}>
                        <Button style={{height: '48px'}} fullWidth variant='contained' color='primary' onClick={this.handleSendSN}>Generate and send</Button>
                    </div>
                    <div style={{textAlign: 'center', marginTop:'24px'}}>
                        <Button fullWidth variant='contained' onClick={this.handleGenSN}>Generate Only</Button>
                    </div>
                    <div style={{textAlign: 'center', marginTop:'12px'}}>
                        <Button fullWidth variant='contained' onClick={this.handleDecodeSN}>Decode</Button>
                    </div>
                </div>
            </Dialog>
        )

        const softwareTable = this.state.filteredSoftware.map((item, i) => {
            return (
                <TableRow key={i} onClick={evt=>this.handleSelection(i)} selected={this.state.selectedRow == i}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.authType}</TableCell>
                    <TableCell>{item.expiration}</TableCell>
                    <TableCell>{item.active_devices.length}</TableCell>
                    <TableCell>{item.owner ? item.owner.email : null}</TableCell>
                    <TableCell>{item.date_purchased.split("T")[0]}</TableCell>
                </TableRow>
            )
        })

        return (
            <div>
                <div className={SoftwareTabStyle.toolbar}>
                    <div className={SoftwareTabStyle.searchBar}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <TextField placeholder="Search" onChange={this.handleSearch}  style={{margin: '12px'}}/>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                {this.state.selectedRow > -1 ? 
                    <div style={{ marginTop: '12px', textAlign: 'center'}}>
                        <Typography variant='h5'>{selectedSoftware ? `${selectedSoftware.name} owned by ${selectedSoftware.owner.firstName} ${selectedSoftware.owner.lastName} (${selectedSoftware.owner._id})` : null}</Typography>
                        <br/>
                        <Button color='primary' onClick={this.handleDeleteProduct}>Send to user</Button>
                        <Button color='secondary' onClick={this.handleDeleteProduct}>Delete Product</Button>
                    </div>
                : null}
                {this.state.selectedRow < 0 ?
                <div style={{ marginTop: '12px', textAlign: 'center'}}>
                    <Button color='primary' onClick={this.openGenSN}>Gen Auth Code</Button>
                </div>
                : null }
                <Table className={SoftwareTabStyle.userTable} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>ExpiresAt</TableCell>
                        <TableCell>Activated Devices</TableCell>
                        <TableCell>Owner</TableCell>
                        <TableCell>Date Purchased</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {softwareTable}
                </TableBody>
                </Table>
                <p style={{fontSize: '14px', textAlign: 'center'}}>{this.state.filteredSoftware.length} software</p>
                {genSerialNumberPopover}
            </div>
        )
    }
}

export default AdminSoftwareTab;